$( document ).ready(function() {


    // On vehicle hover, change image plus show text
    $(".vehicle-lineup-wrapper .vehicle").hover(function () {        
        $(this).find('p').css('display', 'block');
    }, function(){
        $(this).find('p').css('display', 'none');
    });

});